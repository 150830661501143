<div class="container-fluid ml-n2">
  <div class="row">
    <div class="col-5 text-left mt-2">
      <div class="div-usage-guide-style" style="display: flex;">
        <div class="div-usage-guide-message-style">
          <svg width="50" height="25" viewBox="0, 0, 50, 30" xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width="50" height="25" rx="5" ry="5" fill="rgb(231, 76, 60)" />
          </svg>
        </div>
        <div class="div-usage-guide-message-style">
          <span class="ml-2" v-html="$t('message.message_vehicle_system_guide_not_conformed_front')"></span>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none"
              stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
              <path d="M5 12h13M12 5l7 7-7 7" />
            </svg>
            <span>{{ $t('message.message_vehicle_system_guide_not_conformed_rear') }}</span>
          </div>
          <div>
            <span style="width: 50;">{{ $t('message.message_vehicle_system_guide_caution') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-7 text-right text-nowrap mt-2">
      <div>
        <!-- 特約店専用メニュー用のボタン -->
        <b-button
          v-if="displayReproTypeMode === 'campaign' && !isTestMode && (reprogramTableTypes.includes('update') || reprogramTableTypes.includes('restore'))"
          variant="primary" v-on:click="showUpdateProgramList" size="sm">
          {{ $t('message.button_vehicle_system_show_distributor_ecu') }}</b-button>
        <span class="ml-3"></span>
        <!-- RxSWIN表示ボタン -->
        <b-button variant="primary" size="sm" @click="openRxswinModal()">
          {{ $t('message.button_vehicle_system_show_vehicle_rxswin') }}</b-button>
        <span class="ml-3"></span>
        <!-- 公開表示ボタン -->
        <b-button variant="primary" v-on:click="switchTable" v-show="!isPublished && isVisible" size="sm">
          {{ $t('message.button_vehicle_system_show_published') }}</b-button>
        <!-- 非公開表示ボタン -->
        <b-button variant="primary" v-on:click="switchTable" v-show="isPublished && isVisible" size="sm">
          {{ $t('message.button_vehicle_system_show_unpublished') }}</b-button>
      </div>
      <!-- isVisible=true→非公開が表示できる権限 -->
      <div v-if="isVisible" class="mt-2">
        <!-- isPublished=true/false→公開/非公開 -->
        <div v-if='isPublished'>
          <!-- 公開用ラベル -->
          <strong>{{ $t('message.label_vehicle_published') }}</strong>
        </div>
        <div v-else>
          <!-- 非公開用ラベル -->
          <strong>{{ $t('message.label_vehicle_unpublished') }}</strong>
        </div>
      </div>
    </div>
  </div>

  <div class="table-responsive text-left mt-1">
    <!-- displayReproTypeModeがupdateProgram以外の時に表示 -->
    <table class="table" id="su-system-table" v-if="displayReproTypeMode !== 'updateProgram'">
      <!-- テーブルヘッダのカラー -->
      <thead class="thead-dark">
        <tr>
          <!-- テーブルヘッダのソート設定 -->
          <th v-for="h in setupSuTableColumn()" :data-field="h.key" @click="sortCompare(h.key)">
            <span>{{ $t(h.label) }}</span>
            <span v-if="sortCol === h.key">{{ sortDir === 'desc' ? ' ▲' : ' ▼' }}</span>
          </th>
        </tr>
      </thead>
      <!-- 公開 -->
      <!-- isPublished=true/false→公開/非公開 -->
      <tbody v-show="isPublished">
        <!-- 1行ずつ繰り返して挿入 -->
        <tr v-for="(item, i) in publishedTableItems" :key="i">
          <!-- 対象システム -->
          <td v-if="displayReproTypeMode === 'campaign'" :class="item.bgStyle.other">{{
            item.nameForDefaultTbl }}</td>
          <td v-if="displayReproTypeMode === 'update'" :class="item.bgStyle.other">{{
            item.nameForUpdateTbl }}</td>
          <td v-if="displayReproTypeMode === 'restore'" :class="item.bgStyle.other">{{
            item.nameForRestoreTbl }}</td>
          <td v-if="displayReproTypeMode === 'updateChange'" :class="item.bgStyle.other">{{
            item.nameForUpdateRestoreSystemTbl }}</td>
          <!-- hwid -->
          <td :class="item.bgStyle.hwid" class="tbl-line-break-style">{{ item.hwid }}</td>
          <!-- swid -->
          <td :class="setBgStyle(item, 'swid')" class="tbl-line-break-style">{{ item.swid }}</td>
          <!-- CID/ROMID/SpId -->
          <td :class="setBgStyle(item, 'romid')" class="tbl-line-break-style">{{ item.targetId }}</td>
          <!-- リプロ有無の表示 有り/なし -->
          <td v-if="displayReproTypeMode === 'campaign'" :class="item.bgStyle.other">{{
            $t(item.normalReproResource.label) }}</td>
          <td v-if="displayReproTypeMode === 'updateChange'" :class="item.bgStyle.other">{{
            $t(item.updateReproResource.label)
            }}</td>
          <td v-if="displayReproTypeMode === 'updateChange'" :class="item.bgStyle.other">{{
            $t(item.restoreReproResource.label) }}</td>
          <td :class="item.bgStyle.other">
            <!-- 詳細ボタンの表示/非表示判定 -->
            <div v-if="isDetail(item)" class="text-center">
              <b-button variant="info" size="sm" class="btn-style" @click="showDetails(item)">
                {{ $t('message.common_button_details') }}
              </b-button>
            </div>
            <div v-else class="text-center">
              <label size="sm">{{ $t('message.common_hyphen') }}</label>
            </div>
          </td>
          <!-- 搭載・非搭載 -->
          <td v-if="displayReproTypeMode === 'campaign'" :class="item.bgStyle.other">{{
            $t(item.installationResource.label) }}</td>
          <!-- 通信の有・無 -->
          <td v-if="displayReproTypeMode === 'campaign'" :class="item.bgStyle.other">{{
            $t(item.communicableResource.label) }}</td>
        </tr>
      </tbody>
      <!-- 非公開 -->
      <!-- isVisible=true→非公開が表示できる権限 -->
      <!-- isPublished=true/false→公開/非公開 -->
      <tbody v-show="!isPublished && isVisible">
        <!-- 1行ずつ繰り返して挿入 -->
        <tr v-for="(item, i) in unpublishedTableItems" :key="i">
          <!-- 対象システム -->
          <td v-if="displayReproTypeMode === 'campaign'" :class="item.bgStyle.other">{{
            item.nameForDefaultTbl }}</td>
          <td v-if="displayReproTypeMode === 'update'" :class="item.bgStyle.other">{{
            item.nameForUpdateTbl }}</td>
          <td v-if="displayReproTypeMode === 'restore'" :class="item.bgStyle.other">{{
            item.nameForRestoreTbl }}</td>
          <td v-if="displayReproTypeMode === 'updateChange'" :class="item.bgStyle.other">{{
            item.nameForUpdateRestoreSystemTbl }}</td>
          <!-- hwid -->
          <td :class="item.bgStyle.hwid" class="tbl-line-break-style">{{ item.hwid }}</td>
          <!-- swid -->
          <td :class="setBgStyle(item, 'swid')" class="tbl-line-break-style">{{ item.swid }}</td>
          <!-- CID/ROMID/SpId -->
          <td :class="setBgStyle(item, 'romid')" class="tbl-line-break-style">{{ item.targetId }}</td>
          <!-- リプロ有無の表示 有り/なし -->
          <td v-if="displayReproTypeMode === 'campaign'" :class="item.bgStyle.other">{{
            $t(item.normalReproResource.label) }}</td>
          <td v-if="displayReproTypeMode === 'updateChange'" :class="item.bgStyle.other">{{
            $t(item.updateReproResource.label)
            }}</td>
          <td v-if="displayReproTypeMode === 'updateChange'" :class="item.bgStyle.other">{{
            $t(item.restoreReproResource.label) }}</td>
          <td :class="item.bgStyle.other">
            <!-- 詳細ボタンの表示/非表示判定 -->
            <div v-if="isDetail(item)" class="text-center">
              <b-button variant="info" size="sm" class="btn-style" @click="showDetails(item)">
                {{ $t('message.common_button_details') }}
              </b-button>
            </div>
            <div v-else class="text-center">
              <label size="sm">{{ $t('message.common_hyphen') }}</label>
            </div>
          </td>
          <!-- 搭載・非搭載 -->
          <td v-if="displayReproTypeMode === 'campaign'" :class="item.bgStyle.other">{{
            $t(item.installationResource.label) }}</td>
          <!-- 通信の有・無 -->
          <td v-if="displayReproTypeMode === 'campaign'" :class="item.bgStyle.other">{{
            $t(item.communicableResource.label) }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div>
    <!-- アップデートプログラムテーブル -->
    <!-- displayReproTypeModeがupdateProgramの時のみ表示 -->
    <cstm-table useSort currentSortkey="installationResource" initSortDir="asc"
      v-show="displayReproTypeMode === 'updateProgram' && isPublished" rowSelect :items="publishedTableItems"
      :columns="setupSuTableColumn()" class="mt-2 ml-2" type="simple" @rowClicked="showDetails" usePopup
      @mouseHover="onMouseHover" hasTabs>
    </cstm-table>
    <cstm-table useSort currentSortkey="installationResource" initSortDir="asc"
      v-show="displayReproTypeMode === 'updateProgram' && !isPublished && isVisible" rowSelect
      :items="unpublishedTableItems" :columns="setupSuTableColumn()" class="mt-2 ml-2" type="simple"
      @rowClicked="showDetails" usePopup @mouseHover="onMouseHover" hasTabs>
    </cstm-table>
  </div>

  <div v-if="displayReproTypeMode !== 'campaign'" class="text-right">
    <!-- 戻るボタン -->
    <b-button variant="outline-primary" v-on:click="back()" class="ml-2 mr-2 mt-4" size="sm">
      {{ $t('message.common_button_back') }}
    </b-button>
  </div>

  <!-- シリアルNo.確認ダイアログ -->
  <div>
    <b-modal v-model="showModalSerialNo" id="modal-pfc-file-serialNo-input" size="lg" header-bg-variant="secondary"
      header-text-variant="light" no-stacking no-close-on-backdrop centered no-close-on-esc
      @ok="validateInputSerialNo()">
      <template v-slot:header>
        <div class="text-center w-100">
          {{ $t("message.message_title_serial") }}
        </div>
      </template>
      <template v-slot:default>
        <div class="container-fluid mt-2 mb-2">
          <div class="row no-gutters">
            <!-- 説明文 -->
            <div class="mb-3">{{ $t('message.messege_repro_merchantability_password') }}</div>
            <!-- 入力フォーム -->
            <div class="col-12">
              <b-form-group class="form-group">
                <b-input-group>
                  <b-form-input type="text" v-model="inputSerialNo" size="sm" autocomplete="off"
                    :formatter="serialNoFormatter">
                    {{ inputSerialNo }}
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="text-center w-100">
          <!-- キャンセルボタン →  showModalSerialNo=false -->
          <b-button size="sm" variant="outline-primary" class="ml-2 mr-2" @click="showModalSerialNo=false">
            {{ $t('message.common_button_cancel')}}
          </b-button>
          <!-- OKボタン →  checkInputSerialNo() -->
          <b-button size="sm" variant="primary" @click="validateInputSerialNo()" class="ml-2 mr-2">
            {{ $t("message.common_button_ok") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>

  <!-- RxSWIN表示ダイアログ -->
  <b-modal v-model="showModalRxswin" id="modal-rxswin" header-bg-variant="secondary" size="ms"
    header-text-variant="light" no-stacking no-close-on-backdrop centered no-close-on-esc scrollable>
    <template v-slot:header>
      <div class="text-center w-100">
        {{ $t('message.header_vehicle_system_rxswin_of_vehicle') }}
      </div>
    </template>

    <template v-slot:default>
      <div class="row" :class="{ 'scroll-hide': processing }" style="font-weight: bold;">
        <div class="div-rxswin-style text-left text-nowrap">
          <div v-for="rxswin in rxswins">
            {{ rxswin }}
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="text-center">
        <b-button size="sm" variant="primary" @click="copyRxswin()" class="ml-2 mr-2" :disabled="processing">
          {{ $t('message.button_vehicle_system_copy_vehicle_rxswin_to_clipboard') }}
        </b-button>
        <b-button size="sm" variant="primary" @click="showModalRxswin=false" class="ml-2 mr-2" :disabled="processing">
          {{ $t('message.common_button_ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>

  <processing-view :processing="processing"></processing-view>
</div>